import React, { useState, useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import { Input, TextField } from "react-aria-components";

import { FieldConfig } from "components/Form/types";
import { fetchPublicAPI } from "hooks/usePublicAPI";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

// Remove letters and format the phone number
const formatNumber = (number: string) => {
  const cleanedNumber = number.replace(/[A-Za-z\s\-()]/g, "");
  const formattedNumber = cleanedNumber.startsWith("+")
    ? cleanedNumber
    : `+1${cleanedNumber}`;
  return formattedNumber;
};

const validatePhoneNumber = (() => {
  let timeoutId: number | null = null;
  let cache: { [key: string]: boolean } = {};

  return (value: string): Promise<string | boolean> => {
    return new Promise((resolve) => {
      if (!value) {
        resolve("Phone number is required.");
        return;
      }

      const formattedNumber = formatNumber(value);

      if (cache[formattedNumber] !== undefined) {
        resolve(cache[formattedNumber] ? true : "Phone number is invalid.");
        return;
      }

      // Clear previous timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to debounce the API call
      timeoutId = window.setTimeout(async () => {
        try {
          const data = await fetchPublicAPI(
            `/validation/phones/${formattedNumber}`,
          );
          cache[formattedNumber] = data.valid;
          resolve(data.valid ? true : "Phone number is invalid.");
        } catch (err) {
          console.error("Error validating phone number", err);
          // Treat as valid to not block the user
          resolve(true);
        }
      }, 300);
    });
  };
})();

type PhoneNumberFieldProps = {
  field: FieldConfig;
  control: Control;
};

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  field,
  control,
}) => {
  const isRequired = field.validations?.required || false;

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        validate: validatePhoneNumber,
        required: isRequired ? "This field is required." : false,
      }}
      render={({
        field: { name, value, onChange, onBlur, ref },
        fieldState: { invalid, error, isDirty, isValidating },
      }) => {
        const [showError, setShowError] = useState(false);
        const [isFocused, setFocused] = useState(true); // Input is focused by default

        useEffect(() => {
          if (isDirty && !isValidating && !isFocused) {
            setShowError(true);
          } else {
            setShowError(false);
          }
        }, [isDirty, isValidating, isFocused]);

        const handleChange = (e: string) => {
          // Remove letters from the input
          let inputValue = e.replace(/[A-Za-z]/g, "");
          onChange(inputValue);
        };

        return (
          <TextField
            name={name}
            value={value || ""}
            onChange={handleChange}
            onBlur={() => {
              onBlur();
              setFocused(false);
            }}
            onFocus={() => {
              setFocused(true);
            }}
            isRequired={isRequired}
            validationBehavior="aria"
            isInvalid={invalid}
          >
            <FieldLabel text={field.title} />
            <FieldDescription content={field.properties?.description} />
            <Input
              ref={ref}
              type="tel"
              className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
              placeholder={field.properties?.placeholder || "555 867-5309"}
            />
            {showError && error?.message && (
              <FieldError>{error.message}</FieldError>
            )}
          </TextField>
        );
      }}
    />
  );
};

export default PhoneNumberField;
