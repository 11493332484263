import React from "react"

import useIsBusinessHours from "hooks/useIsBusinessHours";
import CallOrSchedule from "components/CallOrSchedule";
import type { CallOrScheduleDetails } from "components/CallOrSchedule/CallOrSchedule";

type HeadshotProps = {
  srcJpg: string;
  srcWebp: string;
  name: string;
  location: string;
};

const Headshot = ({ srcJpg, srcWebp, name, location }: HeadshotProps) => {
  const { isBusinessHours } = useIsBusinessHours();

  return (
    <div className="flex items-start space-x-4">
      <div className="relative inline-block">
        <picture>
          <source srcSet={srcWebp} type="image/webp" />
          <img
            alt={`Headshot of ${name}`}
            loading="lazy"
            src={srcJpg}
            width="512"
            height="512"
            className="h-16 w-16 rounded-full"
          />
        </picture>
        <span
          className="absolute right-0 top-0 hidden h-4 w-4 animate-ping rounded-full bg-green-400 data-[is-bizhours=true]:block"
          data-is-bizhours={isBusinessHours}
        ></span>
        <span
          className="absolute right-0 top-0 hidden h-4 w-4 rounded-full bg-green-400 ring-2 ring-white data-[is-bizhours=true]:block"
          data-is-bizhours={isBusinessHours}
        ></span>
      </div>
      <div className="relative text-left">
        <span className="block font-bold">{name}</span>
        <span className="block text-sm">{location}</span>
      </div>
    </div>
  );
};

type Props = {
  properties: {
    call_or_schedule: CallOrScheduleDetails;
  };
};

const GetInTouch: React.FC<Props> = ({ properties }) => {
  return (
    <div className="mx-auto flex w-full max-w-screen-md flex-col items-center justify-between space-y-8 rounded-lg border border-gray-100 bg-white p-6 shadow-xl lg:space-x-4 lg:p-8">
      <h3 className="text-center font-serif text-2xl font-bold sm:text-4xl">
        Get in touch with us
      </h3>

      <div className="flex w-full flex-col justify-between space-y-8 md:justify-center">
        <div className="flex flex-wrap items-center justify-around gap-6">
          <Headshot
            srcJpg="public/avatar-jonah.jpg"
            srcWebp="public/avatar-jonah.webp"
            name="Jonah"
            location="Provo, UT"
          />
          <Headshot
            srcJpg="public/avatar-brynn.jpg"
            srcWebp="public/avatar-brynn.webp"
            name="Brynn"
            location="Provo, UT"
          />
        </div>

        <div className="flex justify-center">
          <CallOrSchedule details={properties.call_or_schedule} />
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
