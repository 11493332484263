import { useEffect } from "react";
import { useSafeState } from "hooks/useSafeState";

type LoadingProps = {
  message?: string;
  messages?: Array<string>;
  subMessage?: string;
};

export default function Loading({
  message,
  messages,
  subMessage,
}: LoadingProps) {
  const [m, setMessage] = useSafeState(messages ? "Loading..." : message);

  useEffect(() => {
    if (messages) {
      const interval = 4000 * (1 + Math.random());

      const messageUpdater = window.setInterval(() => {
        const randomMessage = messages.shift();

        if (randomMessage) {
          setMessage(randomMessage);
        }
      }, interval);

      return () => {
        clearInterval(messageUpdater);
      };
    }
  }, [messages, setMessage]);

  return (
    <div className="my-24 flex flex-col text-center">
      <div className="after-loader-default mx-auto">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {m && <div className="mt-4 font-serif text-2xl">{m}</div>}
      {subMessage && (
        <div className="mt-2 font-serif text-lg">{subMessage}</div>
      )}
    </div>
  );
}
