import React, { useState, useRef, useEffect } from "react";
import { z } from "zod";
import { usePostHog } from "posthog-js/react";

import useIsBusinessHours from "hooks/useIsBusinessHours";
import { formatPhoneNumber } from "helpers/phone";

import CalendlyEmbedModal from "components/CalendlyEmbedModal";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CalendlySchema = z.object({
  event_url: z.string(),
  after_id: z.string(),
  button_text: z.string().optional(),
  analytics: z
    .object({
      facebook: z
        .object({
          event: z.string(),
          data: z
            .object({
              content_name: z.string().optional(),
              content_category: z.string().optional(),
            })
            .optional(),
        })
        .optional(),
    })
    .optional(),
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PhoneSchema = z.object({
  number: z.string(),
  after_id: z.string(),
  button_text: z.string().optional(),
});

type CalendlyProp = z.infer<typeof CalendlySchema>;
type PhoneProp = z.infer<typeof PhoneSchema>;

export type CallOrScheduleDetails = {
  calendly: CalendlyProp;
  phone: PhoneProp;
};

type CallOrScheduleProps = {
  details: CallOrScheduleDetails;
};

export default function CallOrSchedule({
  details: { calendly, phone },
}: CallOrScheduleProps) {
  const posthog = usePostHog();
  const { isBusinessHours, isBusinessDayTomorrow } = useIsBusinessHours();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLButtonElement>(null);

  const toggleNav = () => {
    posthog?.capture("toggle_call_or_schedule");
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    // Handle clicks outside of the dropdown
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        toggleRef.current &&
        !toggleRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative font-sans text-base font-bold">
      <nav
        ref={dropdownRef}
        className={`absolute top-0 z-0 w-full rounded-b-md rounded-t-[30px] bg-white pt-14 shadow-2xl transition-transform duration-300 ease-in-out ${
          isDropdownVisible ? "block" : "hidden"
        }`}
      >
        <ul className="m-1 text-base">
          <li className="rounded-md transition-all hover:bg-cantelope">
            <a
              href={`tel:${phone.number}`}
              className="flex flex-col p-3 text-center"
            >
              <span className="font-bold">Call us now</span>
              <span className="font-normal">
                {formatPhoneNumber(phone.number)}
              </span>
            </a>
          </li>

          <hr className="my-1" />

          <li className="rounded-md text-center transition-all hover:bg-cantelope">
            <CalendlyEmbedModal
              afterId={calendly.after_id}
              eventUrl={calendly.event_url}
              analytics={calendly.analytics}
              buttonContent={
                <div className="flex flex-col p-3">
                  <span className="font-bold">Schedule a call</span>
                  <span className="font-normal">Pick a date and time</span>
                </div>
              }
            />
          </li>
        </ul>
      </nav>

      <div className="text-center text-sm sm:text-base">
        {!isBusinessHours ? (
          <CalendlyEmbedModal
            afterId={calendly.after_id}
            eventUrl={calendly.event_url}
            analytics={calendly.analytics}
            buttonContent={
              <div className="relative z-10 flex h-14 items-center rounded-full bg-daisy p-2 transition-all hover:bg-daisy-400">
                <span className="mx-2 text-base">📆️</span>
                <div className="mr-2 text-evergreen">
                  {isBusinessDayTomorrow ? (
                    <span>Request a call tomorrow</span>
                  ) : (
                    <span>Schedule a call</span>
                  )}
                </div>
              </div>
            }
          />
        ) : (
          <button
            className="relative z-10 flex h-14 items-center rounded-full bg-daisy px-4 transition-all hover:bg-daisy-400 2xs:p-2 2xs:px-2"
            onClick={toggleNav}
            ref={toggleRef}
          >
            <div className="flex items-center gap-x-2">
              <img
                src="/public/call_us_avatar.webp"
                alt="Call avatar"
                className="hidden h-10 w-10 rounded-full 2xs:block"
              />
              <div className="mr-2 text-evergreen">Call or text us! 👋</div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
}