import React from "react";
import {
  Controller,
  Control,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { ListBox, ListBoxItem, Key, Selection } from "react-aria-components";
import { CheckIcon } from "@heroicons/react/24/outline";

import { FieldConfig } from "components/Form/types";
import { useFormTextSubstitution } from "components/Form/hooks";
import FieldLabel from "./FieldLabel";
import FieldDescription from "./FieldDescription";
import FieldError from "./FieldError";

type ChoicesFieldProps = {
  field: FieldConfig;
  control: Control<FieldValues>;
};

const ChoicesField: React.FC<ChoicesFieldProps> = ({ field, control }) => {
  const { substituteVariables } = useFormTextSubstitution();

  const isRequired = field.validations?.required || false;
  const allowMultipleSelection =
    field.properties?.allow_multiple_selection || false;

  // Extract all choice refs for use when 'all' is selected
  const allChoiceRefs =
    field.properties?.choices?.map((choice) => choice.ref) || [];

  const { setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={field.ref}
      rules={{
        required: isRequired ? "This field is required." : false,
      }}
      render={({ field: { value }, fieldState: { error } }) => {
        // For single selection, value is Key | undefined
        // For multiple selection, value is Key[] | undefined
        const typedValue = value as Key | Key[] | undefined;

        // Build selectedKeys as Iterable<Key> or 'all'
        let selectedKeys: Iterable<Key> | "all" = [];
        if (typedValue) {
          if (allowMultipleSelection) {
            selectedKeys = typedValue as Key[];
          } else {
            selectedKeys = [typedValue as Key];
          }
        }

        const onSelectionChange = (keys: Selection) => {
          if (keys === "all") {
            setValue(field.ref, allChoiceRefs);
          } else {
            const newValue = Array.from(keys);
            if (allowMultipleSelection) {
              setValue(field.ref, newValue, { shouldValidate: true });
            } else {
              setValue(field.ref, newValue[0] ?? null, {
                shouldValidate: true,
              });
            }
          }
        };

        return (
          <div className="mb-4">
            <FieldLabel text={field.title} />
            <FieldDescription content={field.properties?.description} />
            <ListBox
              aria-label={field.title}
              selectionMode={allowMultipleSelection ? "multiple" : "single"}
              selectedKeys={selectedKeys}
              onSelectionChange={onSelectionChange}
              className="mt-4 flex flex-col space-y-4 overflow-auto p-1"
            >
              {field.properties?.choices?.map((choice) => (
                <ListBoxItem
                  key={choice.ref}
                  id={choice.ref}
                  textValue={choice.label}
                  className={({ isSelected, isFocused }) =>
                    `group flex cursor-pointer items-center rounded-md border border-olive bg-white p-4 text-evergreen outline-none ring ring-inset ring-white hover:bg-ivory ${isSelected ? "bg-ivory font-bold" : ""} ${isFocused ? "outline outline-2 outline-cantelope" : ""}`
                  }
                >
                  {!allowMultipleSelection && (
                    <div className="mr-4">
                      <div className="h-3 w-3 rounded-full outline outline-2 outline-offset-2 outline-evergreen group-aria-selected:bg-cantelope"></div>
                    </div>
                  )}
                  {allowMultipleSelection && (
                    <div className="mr-4">
                      <div className="h-3 w-3 rounded-sm outline outline-2 outline-offset-2 outline-evergreen group-aria-selected:bg-cantelope">
                        <CheckIcon className="hidden h-3 w-3 group-aria-selected:block" />
                      </div>
                    </div>
                  )}
                  {substituteVariables(choice.label)}
                </ListBoxItem>
              ))}
            </ListBox>
            {error && <FieldError>{error.message}</FieldError>}
          </div>
        );
      }}
    />
  );
};

export default ChoicesField;
