import { useParams } from "react-router-dom";

import { OnboardingContainer } from "layouts/OnboardingLayout";
import FormLoader from "components/FormLoader";

export default function Form() {
  const { formId, responseId } = useParams<{
    formId: string;
    responseId: string;
  }>();

  return (
    <OnboardingContainer>
      <div className="w-full px-3 sm:px-4">
        <div>
          {!formId && (
            <div className="text-center">
              <h1 className="mb-2 font-serif">
                Sorry, we could not find that form
              </h1>
              <p>
                Please reach out to us at{" "}
                <a
                  href="mailto:support@after.com"
                  target="_blank"
                  className="underline decoration-dotted underline-offset-4 hover:decoration-solid"
                >
                  support@after.com
                </a>{" "}
                if you need help.
              </p>
            </div>
          )}
          {formId && (
            <FormLoader formId={formId} responseId={responseId || "test"} />
          )}
        </div>
      </div>
    </OnboardingContainer>
  );
}
