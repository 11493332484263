import React, { useState } from "react";
import {
  DropZone,
  FileTrigger,
  Button,
  Text,
  Group,
} from "react-aria-components";
import type { DropEvent, FileDropItem } from "react-aria";

type FileUploadProps = {
  accept?: string[];
  allowsMultiple?: boolean;
  onChange: (files: FileList | null) => void;
  className?: string;
};

const FileUpload: React.FC<FileUploadProps> = ({
  accept,
  allowsMultiple = false,
  onChange,
  className,
}) => {
  const [fileNames, setFileNames] = useState<string | null>(null);

  // Handle files from DropEvent, only accepting file drop items
  const handleFilesFromDrop = (e: DropEvent) => {
    const fileDropItems = e.items.filter(
      (item): item is FileDropItem => item.kind === "file",
    );
    const fileNames = fileDropItems.map((fileItem) => fileItem.name);
    setFileNames(fileNames.join(", "));

    // Retrieve File objects asynchronously using getFile method
    Promise.all(fileDropItems.map((item) => item.getFile())).then((files) =>
      onChange(files as unknown as FileList),
    ); // Ensure FileList type compatibility
  };

  // Handle files selected manually via FileTrigger
  const handleFilesFromSelect = (files: FileList | null) => {
    if (files) {
      const fileNames = Array.from(files).map((file) => file.name);
      setFileNames(fileNames.join(", "));
      onChange(files);
    } else {
      setFileNames(null);
      onChange(null); // Handle the case where no files are selected
    }
  };

  return (
    <Group className={className}>
      {/* DropZone for drag-and-drop functionality */}
      <DropZone
        onDrop={handleFilesFromDrop}
        className="border-2 border-dashed border-gray-400 p-4 text-center hover:border-gray-600"
      >
        {({ isHovered }) => (
          <div className={`p-4 ${isHovered ? "border-green-500" : ""}`}>
            {isHovered ? "Drop files here" : "Drag and drop files here or"}

            {/* FileTrigger for manual file selection */}
            <FileTrigger
              allowsMultiple={allowsMultiple}
              acceptedFileTypes={accept}
              onSelect={handleFilesFromSelect}
            >
              <Button className="ml-2 bg-evergreen px-4 py-2 font-semibold text-white">
                Browse Files
              </Button>
            </FileTrigger>
          </div>
        )}
      </DropZone>

      {/* Display the file names after selection or drop */}
      <Text
        slot="label"
        style={{ display: "block" }}
        className="mt-2 text-soil"
      >
        {fileNames || "No files selected"}
      </Text>
    </Group>
  );
};

export default FileUpload;
