import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { useRef, useEffect, useState } from "react";

import config from "config";

export class NetworkError extends Error {
  statusCode: number;
  responseData: any;

  constructor(statusCode: number, responseData?: any) {
    super(`Network Error: ${statusCode}`);
    this.statusCode = statusCode;
    this.responseData = responseData;
  }
}

function api(requestOptions = {}) {
  return async (url: string, options?: any) => {
    const fetchOptions: RequestInit = {
      ...{
        headers: {},
      },
      credentials: "include",
      ...requestOptions,
      ...(options ? { body: JSON.stringify(options.arg) } : {}),
    };

    const response = await fetch(
      `${config.apiEndpoint}/api/v1${url}`,
      fetchOptions,
    );

    const contentType = response.headers.get("content-type");

    if (contentType && contentType.indexOf("application/json") >= 0) {
      const json = await response.json().catch(() => response);

      if (!response.ok) {
        throw new NetworkError(response.status, json);
      }

      return json;
    }

    if (!response.ok) {
      throw new NetworkError(response.status);
    }

    return response;
  };
}

export function fetchPublicAPI(url: string, options = {}) {
  return api(options)(url);
}

type FetchPublicAPIArgs = {
  url: string;
  options?: RequestInit;
  data?: unknown;
};

export function useFetchPublicAPI<T>({
  url,
  options,
  data,
}: FetchPublicAPIArgs) {
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<NetworkError | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const urlRef = useRef(url);
  const optionsRef = useRef(options);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await api(optionsRef.current)(
          urlRef.current,
          data ? { arg: data } : undefined,
        );
        if (isMounted) {
          setResponse(response);
          setError(null);
        }
      } catch (err) {
        if (isMounted) {
          setError(err as NetworkError);
          setResponse(null);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  return { data: response, error, isLoading };
}

// TODO: Don't use any for route type
export function useGET(route: any, requestOptions?: any, swrOptions?: any) {
  const response = useSWR(route, api(requestOptions), swrOptions);

  return response;
}

export function usePOST(route: string, swrOptions?: any) {
  const response = useSWRMutation(
    route,
    api({
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }),
    swrOptions,
  );

  return response;
}

export function usePUT(route: string, swrOptions?: any) {
  const response = useSWRMutation(
    route,
    api({
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    }),
    swrOptions,
  );

  return response;
}

export function useDELETE(route: string, swrOptions?: any) {
  const response = useSWRMutation(
    route,
    api({
      method: "DELETE",
    }),
    swrOptions,
  );

  return response;
}
