export const PLANS: Record<string, string> = {
  simple: "Base",
  value: "Crest",
  choice: "Summit",
};

export type PlanId = "simple" | "value" | "choice";

export type QuoteInfo = {
  event_id: string;
  first_name: string;
  for_self: boolean;
  full_name: string;
  insured_dob: string;
  insured_full_name: string;
  hq1: boolean;
  phone: string;
  zip: string;
  plan: PlanId;
  email: string;
};

type Item = {
  summary: string;
  details: string;
};

export const FAQ: Array<Item> = [
  {
    summary: "What happens when I pass?",
    details:
      "Since you've already prepaid for your cremation services, your family will simply need to contact <a href='https://after.com/' tabindex='0'><strong>After.com</strong></a> to let us know of your passing. We will then file the claim with the insurance provider to obtain the policy benefit and take care of the rest.",
  },
  {
    summary: "What if I decide to cancel?",
    details:
      "You may cancel your policy for a full refund at any time within 30 days of signing. Outside of this 30-day window, you will no longer be eligible for a refund or premiums paid.",
  },
  {
    summary: "Can I pay off my policy early?",
    details: "Yes, there are no pre-payment penalties.",
  },
  {
    summary: "What happens if I die before I've paid my policy?",
    details:
      "In most circumstances, all current accounts will be honored. If you pass away before all payments are made, your insurance may cover the costs of your final expenses, as long as you are outside of the mandatory two-year contestable period. If for some reason you are not current on payments, the amount of your premiums paid to date will still be available for your cremation and funeral services. Any remaining balance will be a lesser amount to be covered by family at the time of arrangements. Likewise, if there were any excess funds remaining from the policy, that money would be returned to your family.",
  },
  {
    summary: "What happens if I pass away while I'm traveling?",
    details:
      "Many funeral homes do not offer Out of Area Protection (OAP) coverage, instead using it as a loophole to avoid paying out benefits. At After, we believe in transparent pricing without hidden fees or extra costs. We offer Out of Area Protection with upfront pricing, ensuring you have the option of protection wherever life takes you. Your peace of mind is our priority.",
  },
  {
    summary: "Is my policy transferable?",
    details:
      "Yes. All policies may be transferred to another funeral home. There is no requirement that After.com be the service provider, but we are honored to do so.",
  },
  {
    summary: "What happens if you go out of business?",
    details:
      "Because your funds are paid directly to our national insurance provider, if something were to happen to After.com, your policy is still effective and insurable through our insurance carrier.",
  },
  {
    summary: "Are my funds safe?",
    details:
      "Yes. Your premiums are paid directly to our national insurance provider, who is licensed and insured in all 50 states.",
  },
  {
    summary:
      "What's the difference between a funeral trust and funeral insurance?",
    details:
      "A funeral trust is when money is paid directly to the funeral home who then places those funds in a trust until you pass. The problem with funeral trusts is that it provides the funeral home with unfettered access to these funds, and unfortunately, there have been cases of funeral homes misusing these funds or going out of business with little recourse for the client. Insurance is different because the premiums are paid directly to the life insurance company. The funeral home will not receive any funds until after your passing, and this money may only be used to cover the cremation expenses.",
  },
  {
    summary: "Can I have multiple beneficiaries?",
    details:
      "Yes, you can. The main beneficiary on a funeral insurance policy is the funeral home. This ensures the policy benefits are paid out immediately after death. If there is any excess of the policy, those funds are returned to the family.",
  },
  {
    summary: "What if I already have life insurance?",
    details:
      "Even if you have life insurance, it’s generally recommended that you preplan your funeral. Typically, the death benefit from life insurance can take weeks, if not months, before it is paid out. In the event of death, your family is required to pay for funeral expenses upfront, which often leaves loved ones with large out-of-pocket expenses before they receive a life insurance benefit.",
  },
  {
    summary: "So, is this life insurance?",
    details:
      "It's similar. Cremation insurance is a unique version of life insurance where the funeral home (<a href='https://after.com/' tabindex='0'><strong>After.com</strong></a>) is the beneficiary. However, the benefit can only be used specifically for funeral expenses.",
  },
  {
    summary: "Can I pay over time?",
    details:
      "Absolutely. We typically offer 3-year and 5-year payment plans. You also have the option to pay for the policy upfront. To determine the best plan for you and your situation, speak to one of our licensed pre-planning specialists today.",
  },
  {
    summary: "How do prepaid cremation plans work?",
    details:
      "Prepaid cremation plans are a type of final expense insurance. The policy you purchase ensures that when you pass, your cremation services are paid for, regardless of what the prices may be at that time.",
  },
  {
    summary: "What are prepaid cremation services?",
    details:
      "Prepaid cremation services allow you to prepay for your cremation services and lock in today's rates. Paying ahead has always been a way to reduce overall costs by side-stepping inflation. Inflation constantly causes the general cost of goods and services to go up, and that includes funeral services. Prepaying for your cremation ensures that when you eventually pass, your final expenses are covered.",
  },
  {
    summary: "Why should I pre-plan my funeral?",
    details:
      "When you pre-plan a funeral, you lock in the price on the day you purchase or begin the installment plan. By locking in the price years before you expect to need the funeral, you can save yourself and your family hundreds (or even thousands) of dollars in funeral expenses. Funerals can leave a heavy burden on loved ones—both the endless decision-making and the costs required. Rather than leaving your loved ones with the worries of arranging and paying for your funeral when you pass, you can prepay, save money and take the burden off your family so they can focus on what matters.",
  },
];
