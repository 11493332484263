import React, { useState } from "react";
import {
  Controller,
  Control,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { TextField, Input, Label } from "react-aria-components";
import { LockClosedIcon, CreditCardIcon } from "@heroicons/react/24/outline";
import { PatternFormat } from "react-number-format";
import cardValidator from "card-validator";

import FieldError from "components/Field/FieldError";

type PaymentMethodFieldProps = {
  control: Control<FieldValues>;
};

const PaymentMethodField: React.FC<PaymentMethodFieldProps> = ({ control }) => {
  const isRequired = true;

  const { watch } = useFormContext();

  const cardNumberField = "cc_number";
  const cardExpField = "cc_exp";
  const cardCvcField = "cc_cvc";
  const cardholderNameField = "cc_full_name";

  const cardNumberValue = watch(cardNumberField);
  const cardInfo = cardValidator.number(cardNumberValue || "");
  const isAmex = cardInfo.card && cardInfo.card.type === "american-express";

  return (
    <div className="mb-4">
      <label className="mb-2 block text-2xl font-bold text-evergreen 2xs:text-3xl">
        Payment Information
      </label>
      <div className="text-sm text-gray-600">
        Less than 0.8% of our customers claim our{" "}
        <strong>30-day money-back guarantee.</strong>
      </div>

      <div className="mt-6 flex flex-col space-y-4">
        <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0">
          {/* Card Number */}
          <Controller
            control={control}
            name={cardNumberField}
            rules={{
              required: "Card number is required.",
              validate: {
                length: (value) =>
                  value && value.length >= 13 && value.length <= 19
                    ? true
                    : "Invalid card number.",
                // Optionally, add Luhn algorithm validation here
                luhnCheck: (value) =>
                  cardValidator.number(value).isValid
                    ? true
                    : "Invalid card number.",
              },
            }}
            render={({
              field: { name, value, onChange, onBlur, ref },
              fieldState: { invalid, error, isDirty, isTouched },
            }) => {
              const [isFocused, setIsFocused] = useState(false);

              return (
                <TextField
                  name={name}
                  value={value || ""}
                  inputMode="numeric"
                  isRequired={isRequired}
                  isInvalid={invalid}
                  className="relative sm:basis-10/12"
                >
                  <Label className="-mb-1 block font-semibold text-evergreen">
                    Card Number
                  </Label>
                  {!value && (
                    <CreditCardIcon className="absolute left-0 top-7 z-0 h-8 w-8 text-soil opacity-30" />
                  )}

                  <PatternFormat
                    value={value || ""}
                    onValueChange={(values) => {
                      const { value: unformattedValue } = values;
                      onChange(unformattedValue);
                    }}
                    format="#### #### #### ####"
                    inputMode="numeric"
                    onBlur={onBlur}
                    customInput={Input}
                    getInputRef={(el: any) => {
                      ref(el);
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlurCapture={() => {
                      onBlur();
                      setIsFocused(false);
                    }}
                    className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
                  />
                  {error?.message && isDirty && isTouched && !isFocused && (
                    <FieldError>{String(error.message)}</FieldError>
                  )}
                </TextField>
              );
            }}
          />

          <div className="flex space-x-2">
            {/* Card Expiration */}
            <Controller
              control={control}
              name={cardExpField}
              rules={{
                required: "Expiration is required.",
                validate: {
                  validDate: (value) => {
                    // value is in MMYY format
                    if (!value || value.length !== 5) {
                      return "Expiration is incomplete.";
                    }

                    const [monthStr, yearStr] = value.split("/");

                    if (
                      !monthStr ||
                      !yearStr ||
                      monthStr.length !== 2 ||
                      yearStr.length !== 2
                    ) {
                      return "Invalid expiration date format.";
                    }

                    const month = parseInt(monthStr, 10);
                    const year = parseInt(yearStr, 10) + 2000; // Assuming years 2000-2099

                    if (
                      isNaN(month) ||
                      isNaN(year) ||
                      month < 1 ||
                      month > 12
                    ) {
                      return "Invalid date.";
                    }

                    const today = new Date();
                    const expirationDate = new Date(year, month - 1, 1);

                    // Set to the end of the expiration month
                    expirationDate.setMonth(expirationDate.getMonth() + 1);
                    expirationDate.setDate(0);

                    if (expirationDate < today) {
                      return "Card is expired";
                    }

                    return true;
                  },
                },
              }}
              render={({
                field: { name, value, onChange, onBlur, ref },
                fieldState: { invalid, error, isDirty, isTouched },
              }) => {
                const [isFocused, setIsFocused] = useState(false);
                return (
                  <TextField
                    name={name}
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                    inputMode="numeric"
                    isInvalid={invalid}
                  >
                    <Label className="-mb-1 block font-semibold text-evergreen">
                      Exp.
                    </Label>
                    <PatternFormat
                      value={value || ""}
                      onValueChange={(values) => {
                        const { formattedValue } = values;
                        onChange(formattedValue);
                      }}
                      format="##/##"
                      placeholder="MM/YY"
                      mask={["M", "M", "Y", "Y"]}
                      inputMode="numeric"
                      onBlur={onBlur}
                      customInput={Input}
                      getInputRef={(el: any) => {
                        ref(el);
                      }}
                      onFocus={() => setIsFocused(true)}
                      onBlurCapture={() => {
                        onBlur();
                        setIsFocused(false);
                      }}
                      className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
                    />
                    {error?.message && isDirty && isTouched && !isFocused && (
                      <FieldError>{String(error.message)}</FieldError>
                    )}
                  </TextField>
                );
              }}
            />

            {/* Card CVC */}
            <Controller
              control={control}
              name={cardCvcField}
              rules={{
                required: "CVC is required.",
                validate: {
                  length: (value) => {
                    const cvcLength = isAmex ? 4 : 3;
                    const regex = new RegExp(`^\\d{${cvcLength}}$`);
                    return regex.test(value)
                      ? true
                      : `CVC must be exactly ${cvcLength} digits.`;
                  },
                },
              }}
              render={({
                field: { name, value, onChange, onBlur, ref },
                fieldState: { invalid, error, isDirty, isTouched },
              }) => {
                const [isFocused, setIsFocused] = useState(false);

                return (
                  <TextField
                    name={name}
                    value={value || ""}
                    inputMode="numeric"
                    isRequired={isRequired}
                    isInvalid={invalid}
                  >
                    <Label className="-mb-1 block font-semibold text-evergreen">
                      CVC
                    </Label>
                    <PatternFormat
                      value={value || ""}
                      onValueChange={(values) => {
                        const { value: unformattedValue } = values;
                        onChange(unformattedValue);
                      }}
                      format={isAmex ? "####" : "###"}
                      placeholder="123"
                      inputMode="numeric"
                      onBlur={onBlur}
                      customInput={Input}
                      getInputRef={(el: any) => {
                        ref(el);
                      }}
                      onFocus={() => setIsFocused(true)}
                      onBlurCapture={() => {
                        onBlur();
                        setIsFocused(false);
                      }}
                      className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
                    />
                    {error?.message && isDirty && isTouched && !isFocused && (
                      <FieldError>{String(error.message)}</FieldError>
                    )}
                  </TextField>
                );
              }}
            />
          </div>
        </div>

        {/* Card Holder Name */}
        <Controller
          control={control}
          name={cardholderNameField}
          rules={{
            required: "Cardholder name is required.",
          }}
          render={({
            field: { name, value, onChange, onBlur, ref },
            fieldState: { invalid, error, isDirty, isTouched },
          }) => {
            const [isFocused, setIsFocused] = useState(false);

            return (
              <TextField
                name={name}
                value={value || ""}
                isRequired={isRequired}
                isInvalid={invalid}
              >
                <Label className="-mb-1 block font-semibold text-evergreen">
                  Cardholder Name
                </Label>
                <Input
                  ref={ref}
                  type="text"
                  onChange={onChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {
                    onBlur();
                    setIsFocused(false);
                  }}
                  className="block w-full rounded-none border-b border-gray-400 bg-transparent py-2 text-2xl font-bold text-soil transition-colors placeholder:text-soil placeholder:text-opacity-25 focus:border-cantelope focus:shadow-focus focus:outline-none"
                />
                {error?.message && isDirty && isTouched && !isFocused && (
                  <FieldError>{String(error.message)}</FieldError>
                )}
              </TextField>
            );
          }}
        />
      </div>

      <div className="mt-4 flex items-center space-x-2">
        <LockClosedIcon className="h-6 w-6 text-green-600" />
        <p className="text-sm text-gray-600">
          Your payment is secure and encrypted.
        </p>
      </div>
    </div>
  );
};

export default PaymentMethodField;
