import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  signInWithEmailLink,
  applyActionCode,
  checkActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";

import { useFirebase } from "firebase";

const EmailActionHandler: React.FC = () => {
  const { auth } = useFirebase();
  const [message, setMessage] = useState("Processing your request...");
  const [error, setError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  const handleEmailSignIn = async (continueUrl: string | null) => {
    try {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        email = window.prompt("Please provide your email for confirmation");
      }

      if (!email) {
        setError("Email is required to complete sign-in.");
        return;
      }

      // Sign in the user with the email link
      await signInWithEmailLink(auth, email, window.location.href);
      window.localStorage.removeItem("emailForSignIn");
      setMessage("You have been signed in successfully!");

      // Redirect after sign-in
      if (continueUrl) {
        navigate(continueUrl);
      } else {
        navigate("/dashboard"); // Default path
      }
    } catch (error: any) {
      console.error("Error signing in with email link:", error);
      setError("An error occurred while signing in.");
    }
  };

  const handleEmailVerification = async (
    oobCode: string | null,
    continueUrl: string | null,
  ) => {
    if (!oobCode) {
      setError("Invalid or missing verification code.");
      return;
    }

    try {
      // Verify the action code before applying it
      await checkActionCode(auth, oobCode);

      // Apply the email verification code
      await applyActionCode(auth, oobCode);

      setMessage("Your email has been verified successfully!");

      // Reload the user to update the emailVerified status
      await auth.currentUser?.reload();

      // Redirect after verification
      if (continueUrl) {
        navigate(continueUrl);
      } else {
        navigate("/dashboard"); // Default path
      }
    } catch (error: any) {
      console.error("Error verifying email:", error);
      setError(
        "Error verifying email. The link may have expired or is invalid.",
      );
    }
  };

  const handlePasswordReset = async (
    oobCode: string | null,
    continueUrl: string | null,
  ) => {
    if (!oobCode) {
      setError("Invalid or missing password reset code.");
      return;
    }

    try {
      // Verify the password reset code
      await verifyPasswordResetCode(auth, oobCode);

      // Ask the user for the new password
      const newPassword = window.prompt("Enter your new password");

      if (!newPassword) {
        setError("Password reset cancelled.");
        return;
      }

      // Save the new password
      await confirmPasswordReset(auth, oobCode, newPassword);

      setMessage("Your password has been reset successfully!");

      // Redirect after password reset
      if (continueUrl) {
        navigate(continueUrl);
      } else {
        navigate("/login"); // Default path
      }
    } catch (error: any) {
      console.error("Error resetting password:", error);
      setError(
        "Error resetting password. The link may have expired or is invalid.",
      );
    }
  };

  const handleEmailChange = async (
    oobCode: string | null,
    continueUrl: string | null,
  ) => {
    if (!oobCode) {
      setError("Invalid or missing verification code for email change.");
      return;
    }

    try {
      // Apply the email change code
      await applyActionCode(auth, oobCode);

      setMessage("Your email address has been updated successfully!");

      // Reload the user to update the email
      await auth.currentUser?.reload();

      // Redirect after email change
      if (continueUrl) {
        navigate(continueUrl);
      } else {
        navigate("/dashboard"); // Default path
      }
    } catch (error: any) {
      console.error("Error updating email address:", error);
      setError(
        "Error updating email address. The link may have expired or is invalid.",
      );
    }
  };

  useEffect(() => {
    const handleAction = async () => {
      const query = new URLSearchParams(location.search);
      const mode = query.get("mode"); // Action mode
      const oobCode = query.get("oobCode"); // Verification code
      const continueUrl = query.get("continueUrl"); // Optional redirect URL

      switch (mode) {
        case "signIn":
          await handleEmailSignIn(continueUrl);
          break;
        case "verifyEmail":
          await handleEmailVerification(oobCode, continueUrl);
          break;
        case "resetPassword":
          await handlePasswordReset(oobCode, continueUrl);
          break;
        case "verifyAndChangeEmail":
          await handleEmailChange(oobCode, continueUrl);
          break;
        default:
          setError("Invalid or unsupported action.");
          break;
      }
    };

    handleAction();
  }, [handleEmailChange, handleEmailSignIn, handleEmailVerification, handlePasswordReset, location.search]);

  return (
    <div className="p-8 text-center">
      {error ? (
        <>
          <h2 className="mb-4 text-2xl font-bold">Error</h2>
          <p className="text-red-500">{error}</p>
        </>
      ) : (
        <>
          <p>{message}</p>
        </>
      )}
    </div>
  );
};

export default EmailActionHandler;
