import { useEffect } from "react";
import { useLocalStorage } from "@uidotdev/usehooks";
import * as Sentry from "@sentry/react";
import { usePostHog } from "posthog-js/react";

import { useGET } from "hooks/usePublicAPI";
import useQueryParams from "hooks/useQueryParams";

function getEventData(data: any) {
  if (!data) return {};

  try {
    return JSON.parse(atob(decodeURIComponent(data)));
  } catch (error) {
    const message = error instanceof Error ? error.message : "Unknown error";
    console.log("Error getting event data", message);
    return {};
  }
}

function parseQuoteInfo(searchParams: Record<string, string>) {
  const eventData = getEventData(searchParams.ped);

  return {
    event_id: searchParams.event_id,
    ...eventData,
  };
}

function getCookie(key: string) {
  const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : undefined;
}

function getPersonSignalId() {
  return getCookie("a_psid");
}

function useQuoteInfo() {
  const posthog = usePostHog();
  const [searchParamsObject] = useQueryParams(); // Already an object now
  const [policyQuote, setPolicyQuote] = useLocalStorage(
    `policy_quote_info:${searchParamsObject.event_id}`,
    parseQuoteInfo(searchParamsObject),
  );

  useEffect(() => {
    if (searchParamsObject.event_id && searchParamsObject.ped) {
      const quoteInfo = parseQuoteInfo(searchParamsObject);
      const personSignalId = getPersonSignalId();

      if (quoteInfo.email) {
        Sentry.setUser({ email: quoteInfo.email });
      }
      if (personSignalId) {
        posthog?.identify(personSignalId, {
          email: quoteInfo.email,
          phone: quoteInfo.phone,
          name: quoteInfo.full_name,
          state: quoteInfo.state,
        });
      }

      Sentry.setTag("quote.event_id", quoteInfo.event_id);

      Sentry.setContext("pre-need-quote", {
        quote_id: quoteInfo.event_id,
        state: quoteInfo.state,
        plan: quoteInfo.plan,
        planning_for_self: quoteInfo.for_self,
      });

      setPolicyQuote(quoteInfo);
    }
  }, [searchParamsObject, posthog, setPolicyQuote]);

  return policyQuote;
}

export function useQuoteContext() {
  const quoteInfo = useQuoteInfo();

  const { data: response } = useGET(
    () =>
      quoteInfo?.event_id ? `/quotes/policy/${quoteInfo.event_id}/quote` : null,
    {},
    {
      refreshWhenHidden: true,
      // @ts-ignore add types for these args
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error?.statusCode === 404) {
          setTimeout(() => revalidate({ retryCount }), 500);
        }

        if (retryCount >= 10) return;
      },
    },
  );

  return { quote: response, quoteInfo };
}
